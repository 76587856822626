import {
  ageGroupCodes,
  employmentTermCodes,
  employmentTypes,
  insuranceExceptionCodes,
  paymentTypes,
  pensionInsuranceTypes
} from '@evelia/helpers/incomesRegisterConstants'
import { ValueOf } from 'type-fest'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { BaseIdModel, BaseSyntheticIdModel } from './types/baseModelTypes'

export type WageEarnerModel = {
  employeeId: number
  salaryGroupId: number | null
  salaryTypeIds: number[]
  receiverId: number | null
} & BaseIdModel

export type TaxDeductionCardModel = {
  wageEarnerId: number
  validFrom: string
  basePercent: number
  extraPercent: number
  incomeLimit: number
  previousIncome: number
} & BaseIdModel

export type EmploymentModel = {
  wageEarnerId: number
  occupationClassificationId: number
  pensionInsuranceId: number | null
  accidentInsuranceId: number | null
  startDate: string
  endDate: string | null
  employmentType: ValueOf<typeof employmentTypes>
  partTimePercent: number | null
  employmentTerm: ValueOf<typeof employmentTermCodes>
  paymentType: ValueOf<typeof paymentTypes>
  carBenefitAgeGroup: ValueOf<typeof ageGroupCodes> | null
  isCarBenefitKilometreBased: boolean
  pensionInsuranceType: ValueOf<typeof pensionInsuranceTypes>
  insuranceExceptions: ValueOf<typeof insuranceExceptionCodes>[]
  salaryTypeIds: number[]
} & BaseIdModel

export type EmploymentSalaryTypeModel = {
  salaryTypeId: number
  employmentId: number
  wageEarnerId: number
  price: number | null
  isIncludedInReports: boolean
} & BaseSyntheticIdModel

export const wageEarnerApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer
})

const getTaxDeductionCardParams = (params: { wageEarnerId: number, id: number }, model: TaxDeductionCardModel) => ({
  ...params,
  id: model?.wageEarnerId ?? params.wageEarnerId,
  subItem: 'tax_deduction_cards',
  subItemId: params.id
})

export const taxDeductionCardApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getTaxDeductionCardParams,
  editMutateParams: getTaxDeductionCardParams
})

const getEmploymentParams = (params: { wageEarnerId: number, id: number }, model: EmploymentModel) => ({
  ...params,
  id: model?.wageEarnerId ?? params.wageEarnerId,
  subItem: 'employments',
  subItemId: params.id
})

export const employmentApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getEmploymentParams,
  editMutateParams: getEmploymentParams
})

const getEmploymentSalaryTypeParams = (params: { wageEarnerId: number, id: number }, model: EmploymentSalaryTypeModel) => ({
  ...params,
  id: params.wageEarnerId,
  subItem: 'employments',
  subItemId: params.id,
  subItemAction: 'salary_types',
  salaryTypeId: model?.salaryTypeId
})

export const employmentSalaryTypeApi = createApi({
  base: 'wage_earners',
  extraUrlTemplate: '{/salaryTypeId}',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getEmploymentSalaryTypeParams,
  editMutateParams: getEmploymentSalaryTypeParams
})
