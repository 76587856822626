import { useEffect, useRef } from 'react'
import { Input as RSInput } from 'reactstrap'
import { clsx } from 'clsx'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'

import FormInputGroupWrapper from './FormInputGroupWrapper'
import FormInputWrapper from './FormInputWrapper'
import { FormPlainInputProps } from './FormPlainInput'

interface FormPlainCheckboxInputProps extends Omit<FormPlainInputProps, 'positionFixed'> {
  value?: boolean
  indeterminate?: boolean
  isButton?: boolean
  buttonCheckboxData?: {
    trueLabel?: React.ReactNode
    trueClassName?: string
    falseLabel?: React.ReactNode
    falseClassName?: string
  }
  labelTooltip?: React.ReactNode
  initialValue?: boolean
}

const FormPlainCheckboxInput = ({
  name,
  value,
  indeterminate,
  errors,
  label,
  inputProps,
  inputOnly,
  required,
  disabled,
  formGroupClassName,
  tooltipError,
  onChange,
  onValueChange,
  isButton,
  buttonCheckboxData = {
    trueLabel: null,
    trueClassName: 'btn-sm btn-outline-primary',
    falseLabel: null,
    falseClassName: 'btn-sm btn-danger'
  },
  isCustomAddon,
  labelTooltip,
  helpText,
  invalid,
  initialValue = false
}: FormPlainCheckboxInputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if(ref.current && isBoolean(indeterminate)) {
      ref.current.indeterminate = !value && indeterminate
    }
  }, [indeterminate, value])

  const booleanLabel = value ? buttonCheckboxData.trueLabel : buttonCheckboxData.falseLabel
  const inputLabel = isButton ? booleanLabel : label
  const buttonClassName = value ? buttonCheckboxData.trueClassName : buttonCheckboxData.falseClassName

  return (
    <FormInputWrapper
      name={name}
      label={inputLabel}
      inputOnly={inputOnly}
      required={required}
      check={!isButton}
      formGroupClassName={formGroupClassName}
      labelClassName={isButton ? clsx('btn', buttonClassName) : 'w-100'}
      tooltip={labelTooltip}
      helpText={helpText}
    >
      <FormInputGroupWrapper
        name={name}
        errors={errors}
        tooltipError={tooltipError}
        isCustomAddon={isCustomAddon}
        positionFixed={false}
      >
        <RSInput
          innerRef={ref}
          id={name}
          invalid={invalid || !!get(errors, name)}
          type='checkbox'
          className={clsx('mt-2', isButton && 'btn-check')} // provide margin due prop size='sm' on <Label /> breaks input
          checked={value == null ? initialValue : value}
          data-testid={name}
          {...inputProps}
          disabled={disabled}
          onChange={event => {
            // Two on changes for possibility to perform two different actions based on a single change
            onValueChange?.(event)
            onChange?.(event)
          }}
        />
      </FormInputGroupWrapper>
    </FormInputWrapper>
  )
}

export default FormPlainCheckboxInput
